/* cyrillic-ext */
@font-face {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/montserrat/v25/JTUSjIg1_i6t8kCHKm459WRhyzbi.woff2)
    format("woff2");
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F,
    U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/montserrat/v25/JTUSjIg1_i6t8kCHKm459W1hyzbi.woff2)
    format("woff2");
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* vietnamese */
@font-face {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/montserrat/v25/JTUSjIg1_i6t8kCHKm459WZhyzbi.woff2)
    format("woff2");
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1,
    U+01AF-01B0, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/montserrat/v25/JTUSjIg1_i6t8kCHKm459Wdhyzbi.woff2)
    format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB,
    U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/montserrat/v25/JTUSjIg1_i6t8kCHKm459Wlhyw.woff2)
    format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
    U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 900;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/montserrat/v25/JTUSjIg1_i6t8kCHKm459WRhyzbi.woff2)
    format("woff2");
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F,
    U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 900;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/montserrat/v25/JTUSjIg1_i6t8kCHKm459W1hyzbi.woff2)
    format("woff2");
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* vietnamese */
@font-face {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 900;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/montserrat/v25/JTUSjIg1_i6t8kCHKm459WZhyzbi.woff2)
    format("woff2");
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1,
    U+01AF-01B0, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 900;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/montserrat/v25/JTUSjIg1_i6t8kCHKm459Wdhyzbi.woff2)
    format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB,
    U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 900;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/montserrat/v25/JTUSjIg1_i6t8kCHKm459Wlhyw.woff2)
    format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
    U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
  font-family: "Ubuntu";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/ubuntu/v20/4iCs6KVjbNBYlgoKcg72j00.woff2)
    format("woff2");
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F,
    U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: "Ubuntu";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/ubuntu/v20/4iCs6KVjbNBYlgoKew72j00.woff2)
    format("woff2");
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* greek-ext */
@font-face {
  font-family: "Ubuntu";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/ubuntu/v20/4iCs6KVjbNBYlgoKcw72j00.woff2)
    format("woff2");
  unicode-range: U+1F00-1FFF;
}
/* greek */
@font-face {
  font-family: "Ubuntu";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/ubuntu/v20/4iCs6KVjbNBYlgoKfA72j00.woff2)
    format("woff2");
  unicode-range: U+0370-03FF;
}
/* latin-ext */
@font-face {
  font-family: "Ubuntu";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/ubuntu/v20/4iCs6KVjbNBYlgoKcQ72j00.woff2)
    format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB,
    U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: "Ubuntu";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/ubuntu/v20/4iCs6KVjbNBYlgoKfw72.woff2)
    format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
    U+FEFF, U+FFFD;
}
p,
h1,
h5,
h2,
.btn {
  font-family: "Montserrat", sans-serif;
}

/* navbar */
nav {
  font-family: "Montserrat", sans-serif;
}

.vcs-logo {
  width: 100px;
  margin-left: 50px;
  width: auto\9;
  height: auto;
}

.centerText {
  text-align: center;
  /* Element must be in container first */
}

.Spinner {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

/* HOME PAGE */

html,
body {
  overflow-x: hidden;
}

#carousel-pics {
  object-fit: cover;
  object-position: center;
  overflow: hidden;
  height: 50%;
  min-height: 50%;
}

.carousel-text {
  color: white;
}

#carouselHero .carousel-item img {
  object-fit: cover;
  object-position: center;
  overflow: hidden;
  height: 70vh;
}

#carouselHero .carousel-item:before {
  content: "";
  background-image: linear-gradient(to bottom, transparent, rgba(0, 0, 0, 0.5));
  display: block;
  position: absolute;
  top: 0;
  width: 100vw;
  height: 100vh;
}
.contactWrapper {
  background-color: #000;
  color: #fff;
  opacity: 0.8;
  height: 100%;
  padding-top: 20px;
  padding-bottom: 20px;
}
/* Puts dark wrapper around any div */
.wrapper {
  background-color: #000;
  color: #fff;
  opacity: 0.7;
  height: 100%;
  padding-top: 20px;
  padding-bottom: 20px;
}

/* Green icons on homepage and their hover effect */
.homeIcon {
  color: #95bc20;
  height: 100px;
  margin-top: 30px;
}

#section2 {
  text-align: center;
  height: 100%;
}
.homeIcon:hover {
  color: #dcff67;
}
article {
  color: #fff;
}
h3 {
  color: black;
  font-family: "Montserrat", sans-serif;
}

#story {
  color: #fff;
  position: relative;
}
/* uses relative path for background CSS using Reacts static media folder */
.ourStory {
  height: 100%;
  position: relative;
  background-attachment: fixed;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  background-image: url("https://ik.imagekit.io/39dbkqr1gb/Team.webp");
  text-align: center;
  overflow: hidden;
}
@media only screen and (-webkit-min-device-pixel-ratio: 2) {
  .ourStory {
    background-size: cover;
    height: 100%;
    background-position: center;
    background-attachment: scroll;
    background-image: url("https://ik.imagekit.io/39dbkqr1gb/Team.webp");
    text-align: center;
  }
}
.story-txt {
  margin-top: 25vh;
  margin-bottom: 25vh !important;
}
.story {
  position: relative;
  bottom: 50%;
}

.c {
  padding-top: 10px;
  padding-bottom: 10px;
  text-align: center;
}

.c-text {
  color: black;
}

.hr1 {
  color: #e60000;
  max-width: 200px;
}

.c-btn {
  margin-bottom: 20px;
}
.SVG {
  height: 200px;
}

/* FOOTER */
.footer {
  color: #fff;
}

.footer-copyright {
  background: #222;
  padding: 5px 0;
}

.footer-copyright p {
  color: #969696;
  margin: 2px 0 0;
}

/* Footer transparent */
footer.transparent .footer-top,
footer.transparent .main-footer {
  background: transparent;
}

footer.transparent .footer-copyright {
  background: none repeat scroll 0 0 rgba(0, 0, 0, 0.3);
}

ul.social-footer2 li {
  display: inline-block;
  padding: 0;
}

ul.social-footer2 li a {
  display: block;
  height: 30px;
  width: 30px;
  text-align: center;
}

.vcsfoot {
  text-align: center;
  justify-content: center;
  height: 100px;
  width: 100px;
  display: flex;
}

.footer-pic {
  justify-content: center;
  width: 200px;
  height: 100px;
}

/* Our Services */
.ourServices {
  font-family: "Montserrat", sans-serif;
  /* min-width: 100%;
  height: 90%;
  background-attachment: fixed;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  color: #fff;
  background-image: url("https://ik.imagekit.io/39dbkqr1gb/servicesBackground.webp") !important;
  overflow: hidden !important; */
}
/* Background for about page on mobile*/
/* @media only screen and (-webkit-min-device-pixel-ratio: 2) {
  .ourServices {
    background-image: url("https://ik.imagekit.io/39dbkqr1gb/servicesBackground.webp") !important;
    background-color: grey;
    background-size: cover;
    background-position: ce;
    background-attachment: scroll;
  }
} */
/* ABOUT US PAGE */

/* Background for about page*/
.background2 {
  min-width: 100%;
  height: 90%;
  background-attachment: fixed;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  color: #fff;
  background-image: linear-gradient(
      to right,
      rgba(102, 212, 234, 0.25),
      rgba(253, 150, 100, 0.25)
    ),
    url("https://ik.imagekit.io/39dbkqr1gb/Team.webp");
}
/* Background for about page on mobile*/
@media only screen and (-webkit-min-device-pixel-ratio: 2) {
  .background2 {
    background-image: linear-gradient(
        to right,
        rgba(102, 212, 234, 0.25),
        rgba(253, 150, 100, 0.25)
      ),
      url("https://ik.imagekit.io/39dbkqr1gb/Team.webp");
    background-size: cover;
    min-width: 100%;
    height: 100%;
    background-position: center;
    background-attachment: scroll;
  }
}
.aboutUsText {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 2em;
  padding: 4em;
  font-family: "Montserrat", sans-serif;
  color: #4d4d4d;
}

.aboutUsText p {
  background: rgba(255, 255, 255, 0.6); /* Glassy effect */
  backdrop-filter: blur(10px); /* Background blur for glassy effect */
  border-radius: 12px;
  padding: 2em;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.15); /* Soft shadow */
  flex-basis: calc(50% - 2em); /* Set width for desktop view */
  line-height: 1.8em;
  transition: all 0.3s ease;
}

.aboutUsText p:hover {
  box-shadow: 0 12px 24px rgba(0, 0, 0, 0.25); /* Shadow effect on hover */
}

.aboutContactBtn {
  margin-top: 2em;
  text-align: center;
}

/* Responsive behavior for smaller screens */
@media (max-width: 768px) {
  .aboutUsText {
    flex-direction: column;
  }

  .aboutUsText p {
    flex-basis: 100%; /* Full width on smaller screens */
    margin-bottom: 1em;
  }
}

.aboutHeader {
  color: #fff;
  position: relative;
  text-align: center;
  margin-top: 20px;
  margin-bottom: 200px;
  font-family: "Montserrat", sans-serif;
}
.aboutHeadContainer {
  position: relative;
  text-align: center;
  top: 45%;
  margin-top: 40vh;
  padding-bottom: 10vh;
}

@media only screen and (-webkit-min-device-pixel-ratio: 2) {
  .aboutHeadContainer {
    position: relative;
    text-align: center;
    top: 45%;
    margin-top: 40vh;
    padding-bottom: 30vh;
  }
}
.aboutContactBtn {
  padding-bottom: 25px;
}

/* CONTACT PAGE */

/* uses relative path for background CSS using Reacts static media folder */
.contactBackground {
  font-family: "Montserrat", sans-serif;
  min-height: 90vh;
  background-attachment: fixed !important;
  background-position: center !important;
  background-size: cover !important;
  background-repeat: no-repeat !important;
  color: #fff;
  background-image: linear-gradient(
      to right,
      rgba(102, 212, 234, 0.55),
      rgba(253, 150, 100, 0.55)
    ),
    url("https://ik.imagekit.io/39dbkqr1gb/waterBackground.jpeg") !important;
  overflow: hidden;
}
.waterBackground {
  background-image: linear-gradient(
    to right,
    rgba(102, 212, 234, 0.55),
    rgba(253, 150, 100, 0.55)
  ),
  url("https://ik.imagekit.io/39dbkqr1gb/waterBackground.jpeg") !important;
overflow: hidden;
}
.contactus {
  padding-bottom: 100px;
  padding-top: 100px;
}
.contactFormInput {
  margin-top: 5px;
  margin-bottom: 5px;
  width: 50vw;
}
.contactFormMessage {
  margin-top: 5px;
  margin-bottom: 5px;
  height: 20vh;
  width: 50vw;
}
.contactFoot {
  margin-top: 10px;
  margin-bottom: 10px;
}

.servicesHeader {
  padding-bottom: 100px;
}

.servicesOverview {
  height: 100% !important;
  color: #fff;
  padding-top: 100px;
  padding-bottom: 100px;
}
.servicesParentDiv {
  min-height: 100vh;

  padding-top: 100px;
  padding-bottom: 100px;
}
.ourServC {
  height: 50px;
  margin-bottom: 20px;
}

/* Landing Page Classes */

.phoneContainer {
  height: 50px;
}

/* CSS to prevent body scrolling when modal is open */
.modal-open-body {
  overflow: hidden;
}

